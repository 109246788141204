import type { Model, UploadedImage } from '../../types';
import { Review as OriginalReviewInterface } from '../melt-connect-reviews/types';

export interface Organization extends Model {
  id: number;
  name: string;
  website: string;
  logo?: string;
}

export type Location = {
  latitude: number | null;
  longitude: number | null;
  location: string | null;
  address: string | null;
};

export type userQuestions = {
  id: number;
  question: string;
  answer: string;
};
interface Project {
  id: number;
  name: string;
  client: Organization;
}

export interface Profile extends Model {
  id: string;
  avatar: UploadedImage | null;
  birthday: string | null;
  email: string;
  firstName: string;
  phoneNumber: string;
  lastName: string;
  jobTitle: string;
  location: Location | null;
  userQuestions: userQuestions[];
  organization: Organization;
  projects: Project[];
  coins: number;
  role: string;
  sendsDailyReport: boolean;
  username: string;
  isActive: boolean;
  updated_at: string;
  skills: Skill[];
  entryDate: string | null;
  hobbies: string | null;
  interests: string | null;
  workExperience: string | null;
  country: string | null;
  vacationDays: number | null;
  levelName: string | null;
  levelDescription: string | null;
}

export interface Skill extends Model {
  name: string;
  id: string;
}

export type Review = OriginalReviewInterface;
export interface ReviewFormInput {
  description: string;
  coins: number;
  userId?: string;
  targetUser?: string;
}
export interface BadgeFormInput {
  published_at: string;
  originManager: string;
  targetTeamUser: string;
  achievements: Badges[];
}
export interface TeamUserBadge extends Model {
  targetTeamUser: string;
  achievements: Profile;
  yesterday: string;
  today: string;
  blockers?: string;
}

export enum Badges {
  Meltalism = 3,
  Bookworm = 1,
  Evolution = 2,
}

export type Role = {
  id: string;
  name: string;
};

export interface Filters {
  role?: string;
  search: string;
  skill?: number;
}
export type Country = {
  name: string;
  value: string;
};
export type Countries = Country[];

export type { OwnState as PeopleState } from './store/reducer';

export type DocumentsAchievements = {
  documentName: string;
  urlDocument: string;
  achievement: {
    title: string;
  };
};
